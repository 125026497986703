import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";

import InfoIcon from "@material-ui/icons/Info";
import blue from "@material-ui/core/colors/blue";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import red from "@material-ui/core/colors/red";

import { searchRegions, postInfoParameterAreas, patchInfoParameterArea, putInfoRegions } from "../api/api.js";

// import ParamAreas from "./SystemParameter/ParamAreas";
// import IconButton from "@material-ui/core/IconButton";
// import LocationIcon from "@material-ui/icons/LocationOn";

// import MapParamTable from "./MapParamTable";

import moment from "moment";

const MOMENT_FORMAT = "MMMM Do YYYY";

const styles = (theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  col1: {
    fontWeight: "bold",
  },
});




function AddRegion({info,p,feature,user,actions}) {
  const [visible, setVisible] = React.useState(false);
  const [regionList,setRegionList] = React.useState([])

  function handleAddRegion(region) {
    handlePostfeature(region)
  }

  async function handlePostfeature(region) {
    try {
      let data = {
        "description": `${region.country.name}, ${region.name}`,
        "osm_id":`R${region.osm_id}`,
        "osm_address":{
            "region":region.name,
            "country":region.country.name,
            "country_code":region.country.iso2_code
        },
        "geom":null
      }
      if (feature!==null) {
        data.description = feature.properties.description
        data.osm_id = feature.properties.osm_id
        // console.log('patchInfoParameterArea....')
        const responsePatch = await patchInfoParameterArea(user,info.identifier,p.id,feature.id,data)
        // console.log(responsePatch)
      } else {
        // console.log('postInfoParameterAreas....')
        const responsePost = await postInfoParameterAreas(user,info.identifier,p.id,data)
        // console.log(responsePost)
      }

      // add region to info if not present
      let set = new Set()
      if (info.regions.length>0) {
        info.regions.forEach(item => {
          set.add(item.id)
        });
      }
      if (!set.has(region.id)) {
        set.add(region.id)
        const regionsID = [...set]
        const data = {"regions":regionsID}
        const responsePut = await putInfoRegions(user,info.identifier,data)
        // console.log(responsePut)  
      }
      actions.reloadInfo(info.identifier)
  
    } catch(err) {
      console.log(err)
    }
  } 

  async function handleRegionCountry() {
    if (visible) {
      setVisible(false)
      setRegionList([])
      return
    }
    try {
      const countriesID = info.countries.map(r => r.id)
      const response = await searchRegions(user, `?country__id=${countriesID[0]}`);
      if (response.hasOwnProperty("results")) {
        setRegionList(response.results)
      }
      setVisible(true)  
    } catch (err) {
      console.log(err)
      alert('error')
    }
  } 

  return (
    <div>
      <div style={{marginBottom:10}}>
        <Button
          disabled={user===undefined}
          onClick={handleRegionCountry}
          variant="outlined"
          color="secondary">{visible ? 'hide region' : 'add region'}
        </Button>
      </div>
      {regionList.map((r,idx)=>
        <div key={`region-${idx}`}>
          <Chip onClick={() => handleAddRegion(r)} label={r.name} />
        </div>)}
    </div>
  )

}


function RowFeature({feature, p, info, user, actions}) {
  const pCountry = feature.properties.country.name;
  let pRegion = null;
  try {
    pRegion = feature.properties.region.name;
  } catch {}
  if (pRegion===null) {
    return <AddRegion info={info} p={p} feature={feature} user={user} actions={actions}/>
  } 
  return (
    <div>
      <div>[{pRegion}, {pCountry}]</div>
    </div>
  )
}


function RowItem({ p, info, handleHistory, user, actions }) {
  const [visible, setVisible] = React.useState(true);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const { features } = p.areas || [];
  return (
    <TableRow>
      <TableCell>
        <Tooltip title={`${JSON.stringify(p.extra)}`} placement="top">
          <InfoIcon style={{ color: blue[500] }} />
        </Tooltip>
      </TableCell>
      <TableCell>{visible && p.system_parameter.value_name}</TableCell>
      <TableCell>
        <div>{visible && p.quantitative}</div>
        <div>
          {p.qualitative !== null && p.qualitative.map((q) => q).join()}
        </div>
      </TableCell>
      <TableCell>{visible && moment(p.onset).format(MOMENT_FORMAT)}</TableCell>
      <TableCell>
        {visible && moment(p.expires).format(MOMENT_FORMAT)}
      </TableCell>
      <TableCell>
        {visible && moment(p.effective).format(MOMENT_FORMAT)}
      </TableCell>
      <TableCell style={{ maxWidth: 200 }}>
        {visible && p.description}
      </TableCell>
      <TableCell>
        {features.length===0 && <AddRegion info={info} p={p} feature={null} user={user} actions={actions}/>}
        {features.map((feature,idx) => 
          <RowFeature 
            key={`params-${idx}`} 
            feature={feature} 
            p={p} info={info} 
            user={user}
            actions={actions}/>)}
      </TableCell>
      <TableCell>
        {confirmDelete && (
          <div>
            <div>Confirm?</div>
            <Button onClick={() => deleteRow()}>yes</Button>
            <Button onClick={() => setConfirmDelete(false)}>no</Button>
          </div>
        )}
        {visible ? (
          <Tooltip title="delete params">
            <DeleteForeverIcon
              onClick={() => setConfirmDelete(true)}
              style={{ color: red[700] }}
            />
          </Tooltip>
        ) : (
          "moved to history"
        )}
      </TableCell>
    </TableRow>
  );

  function deleteRow() {
    setVisible(false);
    setConfirmDelete(false);
    handleHistory(p);
  }
}

class SystemParameterTable extends React.Component {
  render() {
    const { info, user, actions } = this.props;
    const parameters = [...info.parameters];

    return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle2">Parameters</Typography>
        </ExpansionPanelSummary>
        <AccordionDetails>
          <Paper style={{ width: "100%" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>onset</TableCell>
                  <TableCell>expires</TableCell>
                  <TableCell>effective</TableCell>
                  <TableCell>description</TableCell>
                  <TableCell>area</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {parameters.map((p, idx) => (
                  <RowItem
                    key={`params-${idx}`}
                    p={p}
                    info={info}
                    handleHistory={this.props.handleHistory}
                    user={user}
                    actions={actions}
                  />
                ))}
              </TableBody>
            </Table>
          </Paper>
        </AccordionDetails>
      </ExpansionPanel>
    );
  }
}

SystemParameterTable.propTypes = {
  classes: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
};

export default withStyles(styles)(SystemParameterTable);
